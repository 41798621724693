import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    width: 0
}

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setWidthAction: (state, action) => {
            state.width = action.payload
        },
    },
})

export const { setWidthAction } = globalSlice.actions

export default globalSlice.reducer
