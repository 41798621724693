import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Index from './pages/index'
import { setWidthAction } from '@/store/global'

function App() {

	const dispatch = useDispatch()

	useEffect(() => {
		handleResize()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	function handleResize() {
		let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
		dispatch(setWidthAction(width))
	}
	return (
		<div>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Index />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App
