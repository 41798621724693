import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'

import topBg from '@/assets/img/topBg.png'
import logo from '@/assets/img/logo.png'
import facebookIcon from '@/assets/img/facebookIcon.png'
import titleBg from '@/assets/img/titleBg.png'
import titleBgs from '@/assets/img/titleBgs.png'
import right from '@/assets/img/right.png'
import welcomeImg from '@/assets/img/welcomeImg.png'
import use1 from '@/assets/img/use1.png'
import use2 from '@/assets/img/use2.png'
import engage1 from '@/assets/img/engage1.png'
import engage2 from '@/assets/img/engage2.png'
import buttom1 from '@/assets/img/buttom1.png'
import buttom2 from '@/assets/img/buttom2.png'
import down from '@/assets/img/down.png'

import contactUs1 from '@/assets/img/friend1.png'
import contactUs2 from '@/assets/img/friend2.png'
import contactUs3 from '@/assets/img/friend3.png'
import contactUs4 from '@/assets/img/friend4.png'
import contactUs5 from '@/assets/img/friend5.png'

import downApp from '@/assets/img/downApp.png'
import facebook2 from '@/assets/img/facebook2.png'

import headerBg from '@/assets/img/headerBg.png'
import menu from '@/assets/img/menu.png'
import close from '@/assets/img/close.png'
import toApp from '@/assets/img/toApp.png'
import h5TopBg from '@/assets/img/h5TopBg.png'

import './index.css'


function Index() {

	const { width } = useSelector((state) => state.global)
	const [status, setStatus] = useState(false)


	return (
		<div className="App mx-auto overflow-hidden select-none">
			{
				width > 768 ? <>
					<div className='relative h-[1630px]'>
						<img src={topBg} alt='topBg' className='absolute w-[100%] h-[100%] z-[-1]' />
						<div className='w-[1200px] mx-auto pt-[12px]'>
							<div className='fixed z-[10] w-[1200px] h-[80px] bg-[rgba(242,242,242,0.95)] border-[1px] border-[#F2F2F2] border-solid rounded-[100px] flex items-center'>
								<img src={logo} className='w-[109px] ml-[36px] mr-[105px] cursor-pointer' onClick={() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0; }} />
								<div className='text-[18px] text-[#000000] font-semibold flex flex-1'>
									<div className='mr-[44px] cursor-pointer' onClick={() => { document.body.scrollTop = 580; document.documentElement.scrollTop = 580; }}>About Us</div>
									<div className='mr-[44px] cursor-pointer' onClick={() => { document.body.scrollTop = 1400; document.documentElement.scrollTop = 1400; }}>For Users</div>
									<div className='mr-[44px] cursor-pointer' onClick={() => { document.body.scrollTop = 2300; document.documentElement.scrollTop = 2300; }}>For Merchants</div>
									<div className='mr-[44px] cursor-pointer' onClick={() => { document.body.scrollTop = 3000; document.documentElement.scrollTop = 3000; }}>Contact Us</div>
								</div>
								{/* <a rel='noopener noreferrer' href='https://cb.chicha.io/' target="_blank"><img src={facebook1} className='w-[116px] h-[35px] mr-[38px] cursor-pointer' /></a> */}
								<a rel='noopener noreferrer' href='https://cb.chicha.io/' target="_blank"><div className='w-[118px] h-[36px] rounded-[26px] bg-[#000000] text-[14px] font-semibold text-[#FFFFFF] flex justify-center items-center mr-[38px] cursor-pointer'>
									{/* <img src={facebookIcon} className='w-[20px] h-[20px] mr-[6px]' /> */}
									Launch APP
								</div></a>
							</div>
						</div>
						<div className='h-[80px] w-[100%]'></div>
						<div className='flex w-[1200px] mx-auto relative'>
							<img src={down} className='w-[104px] h-[83px] absolute right-[-52px] bottom-[-158px]' />
							<div className='flex-1 pt-[183px]'>
								<div className='text-[68px] text-[#000000] font-bold leading-[88px]'>CHICHA: WEB3 <br /> MARKETPLACE FOR <br /> BRANDS</div>
								{/* <a rel='noopener noreferrer' href='https://cb.chicha.io/' target="_blank"><img src={facebook2} className='w-[168px] h-[49px] mt-[60px] cursor-pointer' /></a> */}
								<div className='text-[32px] text-[#000000] mt-[48px]'>ChiCha is a web-based App</div>
								<a rel='noopener noreferrer' href='https://cb.chicha.io/' target="_blank">
								{/* <div className='w-[168px] rounded-[28px] bg-[#000000] text-[17px] font-semibold text-[#FFFFFF] flex justify-center items-center mr-[38px] cursor-pointer py-[10px] mt-[22px]'> */}
									<img src={downApp} className='w-[290px] h-[85px] mt-[22px]' />
									{/* Launch APP */}
									{/* <img src={right} className='w-[20px] h-[20px] ml-[4px]' /> */}
								{/* </div> */}
								</a>
							</div>
							<img src={titleBg} className='w-[478px] h-[478px] mt-[77px]' />
							<img src={titleBgs} className='w-[652px] h-[490px] absolute top-[83px] right-[-79px] z-[-1]' />
						</div>
						<div className='flex w-[1200px] mx-auto relative'>
							<div className='flex-1 pt-[230px]'>
								<div className='text-[50px] text-[#000000] font-bold leading-[60px]'>Welcome to the Future<br /> of Social Commerce</div>
								<div className='text-[18px] text-[#4B5563] mt-[20px]'>ChiCha is an innovative Web3 social platform built on BSC blockchain technology. Integrating with Facebook, we offer a unique bridge between users and merchants globally, emphasizing decentralized commerce. Our platform features tokenized community memberships, providing users with blockchain-based assets that represent real-world value and engagement.</div>
							</div>
							<img src={welcomeImg} className='w-[376px] h-[376px] mt-[171px] ml-[133px]' />
						</div>
						<div className='w-[1200px] mx-auto relative'>
							<div className='text-[32px] text-[#000000] font-bold'>How to Use ChiCha</div>
							<div className='flex justify-between mt-[28px]'>
								<div className='useBox flex items-center px-[16px]'>
									<img src={use1} className='w-[160px] h-[160px] mr-[16px]' />
									<div>
										<div className='text-[24px] text-[#000000] font-semibold mb-[20px]'>Connect Facebook</div>
										<div className='text-[18px] text-[#272424] font-medium'>Link your Facebook account with ChiCha.<br />Join Communities: Explore and join brand communities on Facebook.</div>
									</div>
								</div>
								<div className='useBoxs flex items-center px-[16px]'>
									<img src={use2} className='w-[160px] h-[160px] mr-[16px]' />
									<div>
										<div className='text-[24px] text-[#000000] font-semibold mb-[20px]'>Trade Tokens</div>
										<div className='text-[18px] text-[#272424] font-medium'>Buy brand tokens and trade them for ChiCha's Merchant tokens.</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='homeCenter'>
						<div className='text-[50px] text-[#000000] font-bold pt-[70px] text-center'>Engage and Earn in a Web3 World</div>
						<div className='flex justify-center pt-[68px]'>
							<div className='homeCenterBox flex flex-col items-center'>
								<img src={engage1} className='w-[160px] h-[160px]' />
								<div className='text-[24px] text-[#000000] font-semibold text-center'>Seamless Entry to Web3<br /> Social Interaction</div>
								<div className='text-[18px] text-[#272424] font-medium text-center'>Invest in tokenized brand communities with minimal barriers, leveraging blockchain technology for both financial gain and enhanced social experiences.</div>
							</div>
							<div className='homeCenterBox flex flex-col items-center'>
								<img src={engage2} className='w-[160px] h-[160px]' />
								<div className='text-[24px] text-[#000000] font-semibold text-center'>Decentralized Trading<br /> with Benefits</div>
								<div className='text-[18px] text-[#272424] font-medium text-center'>Our Web3 trading platform turns social interactions into earning opportunities, driven by decentralized finance mechanisms.</div>
							</div>
						</div>
					</div>
					<div className='lastContent'>
						<div className='text-[50px] text-[#000000] font-bold pt-[125px] text-center mb-[94px]'>Leverage Web3 to Elevate Your Brand</div>
						<div className='lastContentBox1 mx-auto flex items-center'>
							<div>
								<div className='text-[33px] font-semibold text-[#000000]'>Blockchain-Enabled Brand<br /> Communities</div>
								<div className='lastContentBox1div mt-[14px] mb-[35px]'></div>
								<div className='text-[22px] text-[#000000]'>Use blockchain technology to create tokenized, exclusive communities for your help, integrating a decentralized profit-sharing model.</div>
							</div>
							<img src={buttom1} className='w-[332px] h-[298px] ml-[87px]' />
						</div>
						<div className='lastContentBox2 mx-auto flex items-center'>
							<img src={buttom2} className='w-[332px] h-[298px] mr-[122px]' />
							<div>
								<div className='text-[33px] font-semibold text-[#000000]'>Comprehensive Crypto-<br />Finance Tools</div>
								<div className='lastContentBox2div mt-[14px] mb-[35px]'></div>
								<div className='text-[22px] text-[#000000]'>Access a suite of Web3 financial tools, including decentralized lending, crypto exchange, and blockchain-powered marketing strategies.</div>
							</div>
						</div>
						<div className='text-[50px] text-[#000000] font-bold pt-[135px] text-center'>Contact Us</div>
						<div className='flex justify-center mt-[55px]'>
							<a rel='noopener noreferrer' href='https://t.me/ChiCha_Global' target="_blank"><img src={contactUs1} alt='contactUs1' className='w-[54px] h-[54px] mx-[27px] cursor-pointer' /></a>
							<a rel='noopener noreferrer' href='https://discord.gg/E7RdSUWwyy' target="_blank"><img src={contactUs2} alt='contactUs2' className='w-[54px] mx-[27px] h-[54px] cursor-pointer' /></a>
							<a rel='noopener noreferrer' href='https://twitter.com/ChiCha_Global' target="_blank"><img src={contactUs3} alt='contactUs3' className='w-[54px] h-[54px] mx-[27px] cursor-pointer' /></a>
							<a rel='noopener noreferrer' href='https://medium.com/@ChiCha_Global' target="_blank"><img src={contactUs4} alt='contactUs4' className='w-[54px] h-[54px] mx-[27px] cursor-pointer' /></a>
							{/* <a rel='noopener noreferrer' href='https://mmpaypublic.s3.ap-east-1.amazonaws.com/files/ChiCha_WritePaper.pdf' target="_blank"><img src={contactUs5} alt='contactUs5' className='w-[54px] h-[54px] mx-[27px] cursor-pointer' /></a> */}
						</div>
					</div>
				</> : <>
					<div className='w-[100%] pb-[54px] h5Top'>
						<div className='fixed z-[10] h-[60px] w-[100%] bg-[#FFFFFF] border-b-[1px] border-[#DEE1E7] border-solid flex items-center justify-between'>
							<img src={logo} className='w-[64px] h-[33px] ml-[24px]' onClick={() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0; }} />
							<img src={menu} className='w-[16px] h-[16px] mr-[23px]' onClick={() => { setStatus(true) }} />
						</div>
						<div className='h-[60px]'></div>
						<div className='text-[28px] font-bold text-[#000000] pt-[30px] pl-[24px]'>CHICHA: WEB3<br /> MARKETPLACE FOR<br /> BRANDS</div>
						<div className='text-[16px] text-[#000000] mt-[12px]  pl-[24px]'>ChiCha is a web-based App</div>
						<a rel='noopener noreferrer' href='https://cb.chicha.io/' target="_blank">
							<img src={downApp} className='w-[158px] h-[47px] mt-[7px] ml-[24px]' />
						</a>
						{/* <a rel='noopener noreferrer' href='https://cb.chicha.io/' target="_blank"><img src={toApp} className='w-[108px] ml-[24px] mt-[16px]' /></a> */}
						<img src={h5TopBg} className='w-[100%]' />
						<div className='text-[22px] font-bold text-[#000000] pt-[86px] pl-[24px] leading-[26px]'>Welcome to the Future<br /> of Social Commerce</div>
						<div className='px-[24px] text-[12px] text-[#000000] font-medium mt-[10px]'>ChiCha is an innovative Web3 social platform built on BSC blockchain technology. Integrating with Facebook, we offer a unique bridge between users and merchants globally, emphasizing decentralized commerce. Our platform features tokenized community memberships, providing users with blockchain-based assets that represent real-world value and engagement.</div>
						<div className='text-[16px] font-bold text-[#000000] px-[24px] pt-[14px]'>How to Use ChiCha</div>
						<div className='px-[24px]'>
							<div className='h5Use flex mt-[12px] items-center'>
								<img src={use1} className='w-[92px] h-[92px] mr-[5px]' />
								<div>
									<div className='text-[14px] font-semibold text-[#000000] mb-[10px]'>Connect Facebook</div>
									<div className='font-medium text-[12px] text-[#272424]'>Link your Facebook account with ChiCha.Join Communities: Explore and join brand communities on Facebook.</div>
								</div>
							</div>
							<div className='h5Uses flex mt-[17px] items-center'>
								<img src={use2} className='w-[92px] h-[92px] mr-[5px]' />
								<div>
									<div className='text-[14px] font-semibold text-[#000000] mb-[10px]'>Trade Tokens</div>
									<div className='font-medium text-[12px] text-[#272424]'>Buy brand tokens and trade them for ChiCha's Merchant tokens.</div>
								</div>
							</div>
						</div>
					</div>
					<div className='h5EngageBox'>
						<div className='text-[22px] font-bold text-[#000000] leading-[26px]'>Engage and Earn in a<br /> Web3 World</div>
						<div className='h5EngageBoxItem mt-[32px]'>
							<img src={engage1} className='w-[108px] h-[108px] mx-auto' />
							<div className='text-[14px] font-semibold text-[#000000] text-center mt-[9px] mb-[4px]'>Seamless Entry to Web3 Social Interaction</div>
							<div className='text-[12px] font-medium text-[#000000] text-center leading-[16px]'>Invest in tokenized brand communities with minimal barriers, leveraging blockchain technology for both financial gain and enhanced social experiences.</div>
						</div>
						<div className='h5EngageBoxItem mt-[16px]'>
							<img src={engage2} className='w-[108px] h-[108px] mx-auto' />
							<div className='text-[14px] font-semibold text-[#000000] text-center mt-[9px] mb-[4px]'>Decentralized Trading with Benefits</div>
							<div className='text-[12px] font-medium text-[#000000] text-center leading-[16px]'>Our Web3 trading platform turns social interactions into earning opportunities, driven by decentralized finance mechanisms.</div>
						</div>
					</div>
					<div className='h5LastBox'>
						<div className='text-[22px] font-bold text-[#000000] leading-[26px]'>Leverage Web3 to Elevate<br /> Your Brand</div>
						<div className='h5LastBoxItem mt-[32px] text-center'>
							<img src={buttom1} className='w-[108px] h-[108px] mx-auto' />
							<div className='text-[14px] font-semibold text-[#000000] mt-[8px] mb-[8px]'>Blockchain-Enabled Brand Communities</div>
							<div className='text-[12px] font-medium text-[#000000] text-center leading-[16px]'>Use blockchain technology to create tokenized, exclusive communities for your help, integrating a decentralized profit-sharing model.</div>
						</div>
						<div className='h5LastBoxItems mt-[16px] text-center'>
							<img src={buttom2} className='w-[108px] h-[108px] mx-auto' />
							<div className='text-[14px] font-semibold text-[#000000] mt-[8px] mb-[8px]'>Comprehensive Crypto-Finance Tools</div>
							<div className='text-[12px] font-medium text-[#000000] text-center leading-[16px]'>Access a suite of Web3 financial tools, including decentralized lending, crypto exchange, and blockchain-powered marketing strategies.</div>
						</div>
						<div className='text-[22px] font-bold text-[#000000] text-center pt-[52px]'>Contact Us</div>
						<div className='flex justify-center mt-[15px]'>
							<a rel='noopener noreferrer' href='https://t.me/ChiCha_Global' target="_blank"><img src={contactUs1} alt='contactUs1' className='w-[28px] h-[28px] mx-[11px]' /></a>
							<a rel='noopener noreferrer' href='https://discord.gg/E7RdSUWwyy' target="_blank"><img src={contactUs2} alt='contactUs2' className='w-[28px] mx-[11px] h-[28px]' /></a>
							<a rel='noopener noreferrer' href='https://twitter.com/ChiCha_Global' target="_blank"><img src={contactUs3} alt='contactUs3' className='w-[28px] h-[28px] mx-[11px]' /></a>
							<a rel='noopener noreferrer' href='https://medium.com/@ChiCha_Global' target="_blank"><img src={contactUs4} alt='contactUs4' className='w-[28px] h-[28px] mx-[11px]' /></a>
							{/* <a rel='noopener noreferrer' href='https://mmpaypublic.s3.ap-east-1.amazonaws.com/files/ChiCha_WritePaper.pdf' target="_blank"><img src={contactUs5} alt='contactUs5' className='w-[28px] h-[28px] mx-[11px]' /></a> */}
						</div>
					</div>
					{
						status && <div className='w-[100vw] h-[100vh] bg-[#ffffff] fixed z-[20] top-[0] navBox'>
							<img src={headerBg} className='w-[100%] h-[100%] absolute z-[-1]' />
							<div className='fixed z-[10] h-[60px] w-[100%] border-b-[1px] border-[#DEE1E7] border-solid flex items-center justify-between'>
								<img src={logo} className='w-[64px] h-[33px] ml-[24px]' />
								<img src={close} className='w-[16px] h-[16px] mr-[23px]' onClick={() => { setStatus(false) }} />
							</div>
							<div className='h-[60px]'></div>
							<div className='px-[30px] mt-[32px]'>
								<div className='h-[59px] border-b-[1px] border-[#D9D9D9] border-solid text-[16px] text-[#000000] flex items-center' onClick={() => { document.body.scrollTop = 580; document.documentElement.scrollTop = 580; setStatus(false); }}>About Us</div>
								<div className='h-[59px] border-b-[1px] border-[#D9D9D9] border-solid text-[16px] text-[#000000] flex items-center' onClick={() => { document.body.scrollTop = 1150; document.documentElement.scrollTop = 1150; setStatus(false); }}>For Users</div>
								<div className='h-[59px] border-b-[1px] border-[#D9D9D9] border-solid text-[16px] text-[#000000] flex items-center' onClick={() => { document.body.scrollTop = 2000; document.documentElement.scrollTop = 2000; setStatus(false); }}>For Merchants</div>
								<div className='h-[59px] border-b-[1px] border-[#D9D9D9] border-solid text-[16px] text-[#000000] flex items-center' onClick={() => { document.body.scrollTop = 2000; document.documentElement.scrollTop = 2000; setStatus(false); }}>Contact Us</div>
							</div>
							<div className='w-[100%] flex justify-center mt-[15px] absolute bottom-[100px]'>
								<a rel='noopener noreferrer' href='https://t.me/ChiCha_Global' target="_blank"><img src={contactUs1} alt='contactUs1' className='w-[28px] h-[28px] mx-[11px]' /></a>
								<a rel='noopener noreferrer' href='https://discord.gg/E7RdSUWwyy' target="_blank"><img src={contactUs2} alt='contactUs2' className='w-[28px] mx-[11px] h-[28px]' /></a>
								<a rel='noopener noreferrer' href='https://twitter.com/ChiCha_Global' target="_blank"><img src={contactUs3} alt='contactUs3' className='w-[28px] h-[28px] mx-[11px]' /></a>
								<a rel='noopener noreferrer' href='https://medium.com/@ChiCha_Global' target="_blank"><img src={contactUs4} alt='contactUs4' className='w-[28px] h-[28px] mx-[11px]' /></a>
								{/* <a rel='noopener noreferrer' href='https://mmpaypublic.s3.ap-east-1.amazonaws.com/files/ChiCha_WritePaper.pdf' target="_blank"><img src={contactUs5} alt='contactUs5' className='w-[28px] h-[28px] mx-[11px]' /></a> */}
							</div>
						</div>
					}
				</>
			}

		</div>
	);
}

export default Index